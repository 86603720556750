/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from 'src/utils/axios';
import { buildQueryString } from 'src/utils/function';

export const useDebouncedSearch = (query, delay) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [query, delay]);

  useEffect(() => {
    if (debouncedQuery) {
      const fetchResults = async () => {
        const querys = { query: debouncedQuery, limit: 10 };
        setLoading(true);
        try {
          const response = await axiosInstance.get(
            `/flashcard/getallflashcardsforadmin${buildQueryString(querys)}`
          );
          setResults(response.data);
          console.log(response);
        } catch (error) {
          console.error('Error fetching flashcards:', error);
        }
        setLoading(false);
      };

      fetchResults();
    }
  }, [debouncedQuery]);

  return { results, loading };
};

export default useDebouncedSearch;
