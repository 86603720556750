/* eslint-disable prettier/prettier */
import { Navigate, useRoutes } from 'react-router-dom';
// auth
import GroupPage from 'src/pages/Group';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//

import {
  Admin,
  Analytics,
  Course,
  FlashCard,
  HelpAndSupport,
  Language,
  LoginPage,
  Page404,
  Profile,
  ProposedCard,
  Report,
  User
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Analytics /> },
        { path: 'language', element: <Language /> },
        { path: 'flash-card', element: <FlashCard /> },
        { path: 'profile', element: <Profile /> },
        { path: 'course', element: <Course /> },
        { path: 'users', element: <User /> },
        { path: 'admin', element: <Admin /> },
        { path: 'proposed-card', element: <ProposedCard /> },
        { path: 'group', element: <GroupPage /> },
        { path: 'report', element: <Report /> },
        { path: "help-support", element: <HelpAndSupport /> }

      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
