/* eslint-disable prettier/prettier */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import { useState } from 'react';
// @mui
import { Button, IconButton, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import CourseFlashCardList from 'src/components/modal/course-flash-card-list';
import GroupModal from './GroupModal';
import GroupViewModal from './GroupViewModal';
// import GroupFlashCardList from 'src/components/modal/group-flash-card-list'; // Changed import to GroupFlashCardList

export default function GroupTableRow({ row, selected, onEditRow, onDeleteRow }: any) {
    const { name, term, level, language, image, flashCardCount, GroupToLang } = row || {};
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    console.log('row', row);
    const [open, setOpen] = useState(false);
    const [openFlash, setOpenFlash] = useState(false);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleonClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TableRow hover selected={selected} sx={{ height: 92 }}>
                <TableCell>{name}</TableCell>

                {/* <TableCell align="left">{level}</TableCell> */}
                {/* <TableCell align="left" onClick={() => setOpen(true)}>{flashCardCount}</TableCell> */}


                <TableCell align="left" onClick={() => setOpenFlash(true)} style={{ cursor: "pointer", color: "blue" }}>{flashCardCount}</TableCell>
                <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                    {`${GroupToLang?.code ?? ""} - ${GroupToLang?.name ?? language ?? ""} `}

                </TableCell>
                <TableCell align="left">
                    {image ? (
                        <Tooltip title={<img src={image} alt="" />} sx={{ p: 0 }}>
                            <img src={image} alt="" width={60} height={60} />
                        </Tooltip>
                    ) : (
                        'Not found'
                    )}
                </TableCell>
                <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                    {/* {`${da (row?.createdAt ?? "-"}`} */}
                    {row?.createdAt ? `${format(new Date(row?.createdAt), 'dd-MM-yyyy, HH:mm')}` : "-"}
                </TableCell>
                <TableCell align="left">
                    <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{ width: 140 }}
            >
                {/* <MenuItem
                    onClick={() => {
                        onEditRow();
                        handleClosePopover();
                    }}
                >
                    <Iconify icon="eva:eye-outline" />
                    View
                </MenuItem> */}
                <GroupViewModal row={row} />
                <MenuItem
                    onClick={() => {
                        onEditRow();
                        handleClosePopover();
                    }}
                >
                    <Iconify icon="eva:edit-fill" />
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOpenConfirm();
                        handleClosePopover();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="eva:trash-2-outline" />
                    Delete
                </MenuItem>
            </MenuPopover>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content="Are you sure want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={onDeleteRow}>
                        Delete
                    </Button>
                }
            />
            {
                open && <GroupModal selectedRow={row} open={open} onClose={handleonClose} /> // Changed to GroupFlashCardList
            }
            {
                openFlash && <CourseFlashCardList data={row} open={openFlash} onClose={() => {
                    setOpenFlash(false)
                }} />
            }
        </>
    );
}
