/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  Box,
  IconButton,
  Grid,
  Typography,
  MenuItem,
  Card,
  CardContent,
  Chip,
  Avatar,
} from '@mui/material';
import useSound from 'use-sound';
import CourseFlashCard from '../cards/course-flash-card';

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  data: any;
}
const CourseFlashCardList = ({
  open,
  onClose,
  data,
}: Props) => {



  console.log("first", data)
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            paddingTop: 2,
            paddingX: 3,
            paddingBottom: 0,
            textAlign: 'center',
          }}
        >
          Flash Card List
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // textAlign: 'center',
            padding: '0px',
          }}
        >
          {
            data?.FlashCards?.map((itemData: any) => <Grid item xs={12} md={24} padding={3} width="100%">
              <CourseFlashCard itemData={itemData} />
            </Grid>
            )
          }

        </DialogContent>
      </Dialog>
    </>
  );
};

export default CourseFlashCardList;
