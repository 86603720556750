import { ElementType, Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const Profile = Loadable(lazy(() => import('../pages/Profile')));
export const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
export const Report = Loadable(lazy(() => import('../pages/Report')));
export const HelpAndSupport = Loadable(lazy(() => import('../pages/HelpAndSupport')));
export const Language = Loadable(lazy(() => import('../pages/Language')));

export const FlashCard = Loadable(lazy(() => import('../pages/FlashCard')));
export const Analytics = Loadable(lazy(() => import('../pages/Analytics')));
export const Admin = Loadable(lazy(() => import('../pages/Admin')));
export const Course = Loadable(lazy(() => import('../pages/Course')));
export const User = Loadable(lazy(() => import('../pages/User')));
export const ProposedCard = Loadable(lazy(() => import('../pages/ProposedCard')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
