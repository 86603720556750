/* eslint-disable prettier/prettier */
import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, IconButton, InputAdornment, Stack } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import { useSnackbar } from 'notistack';
import RHFOTPField from 'src/components/hook-form/RHFOTP';
import { phoneRegExp } from 'src/utils/function';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

type FormValuesProps = {
  phone: string;
  password: string;
  newpassword: string;
  confirmpassword: string;
  afterSubmit?: string;
};

export default function AuthLoginForm() {
  const { login, forgetPassword, resetPassword } = useAuthContext();
  const [forgotModal, setForgotModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otpToekn, setOtpToekn] = useState(null);

  const [showPassword, setShowPassword] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    //  phone: Yup.string().required('Phone is required'),
    password: Yup.string().required('Password is required'),
  });
  const ForgotSchema = Yup.object().shape({
    // email: Yup.string().required('Email is required'),
    phone: Yup.string()
      .required('Phone Number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    // newpassword: Yup.string().required('Password is required'),
    // confirmpassword: Yup.string().required('Confirm Password is required'),
  });
  const ResetSchema = Yup.object().shape({
    OTP: Yup.string().required('OTP is required'),
    newpassword: Yup.string().required('Password is required'),
    confirmpassword: Yup.string().required('Confirm Password is required'),
  });

  const defaultValues = {
    // phone: '+911234567893',
    // password: 'admin@1234',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(otpModal ? ResetSchema : forgotModal ? ForgotSchema : LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,

    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    // console.log(data);
    if (forgotModal && !otpModal) {
      // if (data.newpassword === data.confirmpassword && data.phone) {

      // console.log(data.newpassword, data.confirmpassword, "")
      // const token = await forgetPassword(data.email);
      const token = await forgetPassword(data.phone);
      setOtpToekn(token)
      reset();
      setOtpModal(true);
      // }
      // setForgotModal(false)
    } else if (!otpModal) {
      try {
        await login(data.email, data.password);
        // await login(data.phone, data.password);
      } catch (error) {
        console.error(error);

        reset();

        setError('afterSubmit', {
          ...error,
          message: error.message || error,
        });
      }
    }
    else {
      try {
        await resetPassword(data.OTP, otpToekn, data.newpassword)

        enqueueSnackbar('Password Updated Successfully', { variant: 'success' });
      } catch (error) {
        console.error(error);

        reset();

        setError('afterSubmit', {
          ...error,
          message: error.message || error,
        });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        {!forgotModal && !otpModal && <RHFTextField name="email" type="email" label="Email" />}
        {/* {!otpModal && <RHFTextField name="phone" label="Phone Number" />} */}
        {forgotModal && !otpModal && <RHFTextField name="phone" label="Phone Number" />}
        {!forgotModal && (
          <RHFTextField
            name="password"
            label="Password"
            type={showPassword === 1 ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(showPassword === 1 ? 0 : 1)}
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        {otpModal && (
          <>
            <RHFOTPField name="OTP" label="OTP" />
            <RHFTextField
              name="newpassword"
              label="New Password"
              type={showPassword === 2 ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(showPassword === 2 ? 0 : 2)}
                      edge="end"
                    >
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />{' '}
            <RHFTextField
              name="confirmpassword"
              label="Confirm Password"
              type={showPassword === 3 ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(showPassword === 3 ? 0 : 3)}
                      edge="end"
                    >
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {/* {
          !forgotModal ?
            <RHFTextField
              name="password"
              label="Password"
              type={showPassword === 1 ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(showPassword === 1 ? 0 : 1)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            :
            <>
             <RHFTextField
                name="newpassword"
                label="New Password"
                type={showPassword === 2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(showPassword === 2 ? 0 : 2)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />  <RHFTextField
                name="confirmpassword"
                label="Confirm Password"
                type={showPassword === 3 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(showPassword === 3 ? 0 : 3)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> 
            </>

        } */}

        <div
          className="d-flex"
          style={{ display: 'flex', justifyContent: 'end', marginTop: '0px' }}
        >
          <Button
            onClick={() => {
              setForgotModal((prev) => !prev);
              setOtpModal(false);
            }}
          >
            {!forgotModal ? 'Forgot Password' : 'Already have an account ?'}
          </Button>
        </div>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        // loading={isSubmitSuccessful || isSubmitting}
        sx={{
          bgcolor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          },
        }}
      >
        {forgotModal ? 'Submit' : 'Login'}
      </LoadingButton>
    </FormProvider>
  );
}
