/* eslint-disable prettier/prettier */
import { InputAdornment, TextField, Autocomplete, Chip } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Iconify from '../iconify/Iconify';
import { debounce } from 'lodash';

const FlashcardGroupAdd = ({ setSearch, placeholder = "Search", autocomplete = false, options = [], handleOptionChange, loadMoreOptions }) => {
    // const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSearchChange = useCallback(
        debounce((event) => {
            setSearch(event.target.value);
            if (autocomplete) {
                loadMoreOptions();
            }
        }, 500),
        [setSearch, loadMoreOptions, autocomplete]
    );


    return (
        <div style={{ marginBottom: "15px" }}>

            <Autocomplete
                freeSolo
                options={options}
                getOptionLabel={(option) => option.term}
                onChange={(ev, value) => { handleOptionChange(value) }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        onChange={handleSearchChange}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify
                                        icon="eva:search-fill"
                                        sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />

        </div>
    );
};

export default FlashcardGroupAdd;