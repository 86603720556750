import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';
import { buildQueryString } from 'src/utils/function';

interface AdminState {
  adminsData: {
    count: number;
    rows: any;
  };
  adminsDataloading: boolean;
  getAdminData: any;
  getAdminDataLoading: boolean;
  fetchAdminData: any;
  fetchAdminDataLoading: boolean;
  fetchAdminDataSuccess: boolean;
  fetchAdminDataError: boolean;
  updateFlashCardSuccess: boolean;
  updateFlashCardError: boolean;
  updateFlashCardLoading: boolean;
  deleteAdminSuccess: boolean;
  deleteAdminError: boolean;
  deleteAdminLoading: boolean;
  deleteFlashCardSuccess: boolean;
  deleteFlashCardError: boolean;
  deleteFlashCardLoading: boolean;
}

const initialState: AdminState = {
  adminsData: {
    count: 0,
    rows: [],
  },
  adminsDataloading: false,
  getAdminData: {},
  getAdminDataLoading: false,
  fetchAdminData: {},
  fetchAdminDataError: false,
  fetchAdminDataLoading: false,
  fetchAdminDataSuccess: false,
  updateFlashCardError: false,
  updateFlashCardSuccess: false,
  updateFlashCardLoading: false,
  deleteAdminError: false,
  deleteAdminSuccess: false,
  deleteAdminLoading: false,
  deleteFlashCardSuccess: false,
  deleteFlashCardError: false,
  deleteFlashCardLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startAdminloading(state) {
      state.adminsDataloading = true;
    },
    getAdminData(state, action: PayloadAction<any>) {
      state.adminsDataloading = false;
      state.adminsData = action.payload;
    },
    stopAdminLoading(state) {
      state.adminsDataloading = false;
    },
    startGetAdminloading(state) {
      state.getAdminDataLoading = true;
    },
    getAdminMyData(state, action: PayloadAction<any>) {
      state.getAdminDataLoading = false;
      state.getAdminData = action.payload;
    },
    stopGetAdminLoading(state) {
      state.getAdminDataLoading = false;
    },
    startAdminByIdloading(state) {
      state.fetchAdminDataLoading = true;
    },
    getAdminByIdData(state, action: PayloadAction<any>) {
      state.fetchAdminDataLoading = false;
      state.fetchAdminData = action.payload;
    },
    stopAdminByIdLoading(state) {
      state.fetchAdminDataLoading = false;
    },
    updateFlashcardByIdLoading(state) {
      state.updateFlashCardLoading = true;
    },
    updateFlashcardByIdSuccess(state) {
      state.updateFlashCardSuccess = true;
      state.updateFlashCardLoading = false;
    },
    updateFlashcardByIdError(state) {
      state.updateFlashCardError = true;
      state.updateFlashCardLoading = false;
    },
    deleteAdminLoading(state) {
      state.deleteAdminLoading = true;
    },
    deleteAdminSuccess(state) {
      state.deleteAdminSuccess = true;
      state.deleteAdminLoading = false;
    },
    deleteAdminError(state) {
      state.deleteAdminError = true;
      state.deleteAdminLoading = false;
    },
    clearDeleteAdminData(state) {
      state.deleteFlashCardLoading = false;
      state.deleteFlashCardError = false;
      state.deleteFlashCardSuccess = false;
    },
    deleteFlashCardByIdLoading(state) {
      state.deleteFlashCardLoading = true;
    },
    deleteFlashCardByIdSuccess(state) {
      state.deleteFlashCardSuccess = true;
      state.deleteFlashCardLoading = false;
    },
    deleteFlashCardByIdError(state) {
      state.deleteFlashCardError = true;
      state.deleteFlashCardLoading = false;
    },
  },
});

export const {
  startAdminloading,
  getAdminData,
  stopAdminLoading,
  updateFlashcardByIdError,
  updateFlashcardByIdLoading,
  updateFlashcardByIdSuccess,
  deleteAdminError,
  deleteAdminSuccess,
  deleteAdminLoading,
  getAdminByIdData,
  startAdminByIdloading,
  stopAdminByIdLoading,
  getAdminMyData,
  startGetAdminloading,
  stopGetAdminLoading,
  deleteFlashCardByIdError,
  deleteFlashCardByIdLoading,
  deleteFlashCardByIdSuccess,
  clearDeleteAdminData,
} = userSlice.actions;

export default userSlice.reducer;

// export const getAllAdmins =
//   (page: number, pageSize: number): any =>
//   async (dispatch: any) => {
//     try {
//       dispatch(startAdminloading());
//       const response = await axiosInstance.get(
//         `/admins/admin-by-admin?page=${page}&pageSize=${pageSize}`
//       ); // Replace with your API endpoint
//       dispatch(getAdminData(response.data.data));
//     } catch (error) {
//       dispatch(stopAdminLoading(error.message));
//     }
//   };
export const getAllAdminswithQuery =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startAdminloading());
      const response = await axiosInstance.get(`/admins/admin-by-admin${buildQueryString(query)}`); // Replace with your API endpoint
      dispatch(getAdminData(response.data.data));
    } catch (error) {
      dispatch(stopAdminLoading(error.message));
    }
  };

export const getAdmin = (): any => async (dispatch: any) => {
  try {
    dispatch(startGetAdminloading());
    const response = await axiosInstance.get(`/admins/admin-by-admin`); // Replace with your API endpoint
    dispatch(getAdminMyData(response.data));
  } catch (error) {
    dispatch(stopGetAdminLoading(error.message));
  }
};

export const updateFlashCardById =
  (data: any, id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateFlashcardByIdLoading());
      await axiosInstance.patch(`/flashcard/updateflashcardbyid/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); // Replace with your API endpoint
      dispatch(updateFlashcardByIdSuccess());
    } catch (error) {
      dispatch(updateFlashcardByIdError());
    }
  };

export const getAdminById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(startAdminByIdloading());
      const response = await axiosInstance.get(`/admins/admin-by-admin/${id}`); // Replace with your API endpoint
      dispatch(getAdminByIdData(response.data));
    } catch (error) {
      dispatch(stopAdminByIdLoading(error.message));
    }
  };
export const createAdmin =
  (data: any, enqueueSnackbar: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(startAdminloading());
      const response = await axiosInstance.post(`/admins/admin-by-admin/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); // Replace with your API endpoint
      dispatch(getAllAdminswithQuery({ page: 1, pageSize: 10 }));
      // dispatch(getAdmin(response.data));
    } catch (error) {
      dispatch(stopAdminLoading(error.message));
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
export const deleteAdmin =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteAdminLoading());
      await axiosInstance.delete(`/admins/admin-by-admin/${id}`); // Replace with your API endpoint
      dispatch(deleteAdminSuccess());
      dispatch(getAllAdminswithQuery({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(deleteAdminError());
    }
  };

export const deleteFlashCardById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteFlashCardByIdLoading());
      await axiosInstance.patch(`/flashcard/deleteflashcardbyid/${id}`); // Replace with your API endpoint
      dispatch(deleteFlashCardByIdSuccess());
    } catch (error) {
      dispatch(deleteFlashCardByIdError());
    }
  };
