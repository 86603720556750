/* eslint-disable prettier/prettier */
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Box,
    Button,
    Container,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSearchBar from 'src/components/common/CustomSearchBar';
import {
    TableHeadCustom,
    TableNoData,
    TableSkeleton,
    useTable
} from 'src/components/table';
import GroupModal from 'src/sections/groupList/GroupModal';
import GroupTableRow from 'src/sections/groupList/GroupTableRow';
import {
    clearCreateGroupData,
    clearDeleteGroupData,
    clearUpdateGroupData,
    deleteGroup,
    getAllGroups
} from 'src/store/slices/groupListslice';
import { RootState } from 'src/store/store';
import { useSettingsContext } from '../components/settings';
import { useSnackbar } from '../components/snackbar';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left', sortable: true },
    { id: 'flashCardCount', label: 'Count', align: 'left', sortable: true },
    { id: 'language', label: 'Language', align: 'left', sortable: true },
    { id: 'image', label: 'Image', align: 'left' },
    { id: 'createdAt', label: 'createdAt', align: 'left', sortable: true },
    { id: 'action', label: 'Action', align: 'left' },
];

const GroupPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [id, setId] = useState(-1);
    const [searchTerm, setSearchTerm] = useState("");

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        setRowsPerPage,
        setPage,
        selected,
        onSort,
        onSelectRow,
    } = useTable();

    const { themeStretch } = useSettingsContext();

    const { groupData,
        groupDataLoading,
        groupWithFlashcardData,
        groupWithFlashcardDataLoading,
        createGroupError,
        createGroupSuccess,
        deleteGroupSuccess,
        deleteGroupError,
        // updateGroupErrorMessage,
        // deleteGroupErrorMessage,
        // createGroupErrorMessage,
        updateGroupError,
        updateGroupSuccess,
    } = useSelector((state: RootState) => state.group);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllGroups({
            page: page + 1, pageSize: rowsPerPage, searchTerm, orderByAsc: order === 'asc',
            orderColumn: orderBy,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, page, rowsPerPage, order, orderBy]);

    useEffect(() => {
        if (deleteGroupSuccess) {
            enqueueSnackbar('Group deleted successfully', {
                variant: 'success',
            });
            dispatch(clearDeleteGroupData());
        }
        if (deleteGroupError || updateGroupError || createGroupError) {
            // enqueueSnackbar(updateGroupErrorMessage?.message ?? deleteGroupErrorMessage?.message ?? createGroupErrorMessage?.message ?? 'Something went wrong', {
            //     variant: 'error',
            // });
            if (createGroupError) dispatch(clearCreateGroupData());
            if (deleteGroupError) dispatch(clearDeleteGroupData());
            if (updateGroupError) dispatch(clearUpdateGroupData());
        }
        if (createGroupSuccess) {
            enqueueSnackbar('Group created successfully', {
                variant: 'success',
            });
            dispatch(clearCreateGroupData());
        }
        if (updateGroupSuccess) {
            enqueueSnackbar('Group updated successfully', {
                variant: 'success',
            });
            dispatch(clearUpdateGroupData());
        }
        setPage(0);
        handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        createGroupSuccess,
        createGroupError,
        deleteGroupError,
        deleteGroupSuccess,
        updateGroupError,
        updateGroupSuccess,
    ]);
    console.log(groupData)
    const isNotFound = groupData?.rows?.length === 0;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setId(-1);
        setSelectedRow({});
        // dispatch(getAllGroups({ page: page + 1, pageSize: rowsPerPage, searchTerm }));
        setOpen(false);
    };

    const onEditRow = (rowId: number) => {
        setId(rowId);
        handleClickOpen();
    };

    const onDeleteRow = (rowId: number) => {
        dispatch(deleteGroup(rowId));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        // dispatch(getAllGroups({ page: newPage + 1, pageSize: rowsPerPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // dispatch(getAllGroups({ page: page + 1, pageSize: parseInt(event.target.value, 10) }));
    };

    return (
        <>
            <Helmet>
                <title> Group | Perrolingo Admin Panel</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" component="h1" paragraph>
                        Group
                    </Typography>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <CustomSearchBar setSearchTerm={setSearchTerm} placeholder='Search Group' />
                        <Button
                            variant="outlined"
                            onClick={() => {
                                handleClickOpen();
                            }}
                        >
                            Add Group
                        </Button>
                    </div>
                </Box>

                <TableContainer sx={{ minHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeadCustom
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={groupData?.rows?.length}
                            numSelected={selected.length}
                            onSort={(sortCol) => {
                                const column = TABLE_HEAD.find((col) => col.id === sortCol);
                                if (column && column?.sortable) {
                                    onSort(sortCol);
                                }
                            }}
                        />
                        <TableBody>
                            {groupDataLoading
                                ? [...Array(rowsPerPage)]
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.map((item, index) => <TableSkeleton count={TABLE_HEAD?.length} />)
                                : groupData?.rows?.map((row: any) => (
                                    <>

                                        <GroupTableRow
                                            key={row.id}
                                            row={row}
                                            selected={selected?.includes(row.id)}
                                            onSelectRow={() => onSelectRow(row.id)}
                                            onEditRow={() => {
                                                setSelectedRow(row);
                                                onEditRow(row?.id);
                                            }}
                                            onDeleteRow={() => {
                                                onDeleteRow(row?.id);
                                            }}
                                        />
                                        {/* {id === row?.id && open && (
                                            <GroupModal open={open} onClose={() => handleClose()} />
                                        )} */}
                                    </>
                                ))}
                            {groupDataLoading === false && <TableNoData isNotFound={isNotFound} />}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={groupData?.count}
                    // count={groupData?.count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 15, 25]}
                />
            </Container>
            {open && (
                <GroupModal
                    open={open}
                    editId={id}
                    selectedRow={selectedRow}
                    onClose={() => handleClose()}
                />
            )}
        </>
    );
}

export default GroupPage;
