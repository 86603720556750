// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, TextFieldProps } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFOTPField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => value.length === 4 }}
      render={({ field, fieldState }) => (
        <Box>
          <MuiOtpInput sx={{ gap: 1 }} {...field} length={4} />
          {fieldState.invalid ? (
            <FormHelperText error>OTP invalid</FormHelperText>
          ) : null}
        </Box>
      )}
    />
  );
}
