/* eslint-disable prettier/prettier */
// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  profile: 'profile',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  language: path(ROOTS_DASHBOARD, '/language'),
  // dashboard: path(ROOTS_DASHBOARD, '/home'),
  flashCard: path(ROOTS_DASHBOARD, '/flash-card'),
  course: path(ROOTS_DASHBOARD, '/course'),
  users: path(ROOTS_DASHBOARD, '/users'),
  admin: path(ROOTS_DASHBOARD, '/admin'),
  proposedCard: path(ROOTS_DASHBOARD, '/proposed-card'),
  group: path(ROOTS_DASHBOARD, '/group'),
  report: path(ROOTS_DASHBOARD, '/report'),
  helpandSupport: path(ROOTS_DASHBOARD, '/help-support'),
};
