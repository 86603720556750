/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFlashCardForAdmin } from 'src/store/slices/flashCardSlice';
import CustomSearchBar from '../common/CustomSearchBar';
import { RHFMultiSelect } from '../hook-form';

const MultipleSelectField = ({
    filteredFlashcards,
    selectedFlashCards,
    setSelectedFlashcards,
    query = {},
}: any) => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');

    const { flashCardData } = useSelector((state: RootState) => state.flashCard);
    // console.log(flashCardData?.rows)
    useEffect(() => {
        dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 20, searchTerm, ...query }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <CustomSearchBar
                setSearchTerm={setSearchTerm}
                placeholder="Search Flashcard"
                autocomplete
                options={flashCardData?.rows}
                loadMoreOptions={() => {
                    dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 20, searchTerm, ...query }));
                }}
                selectedOptions={selectedFlashCards}
                setSelectedOptions={setSelectedFlashcards}
            />
            <RHFMultiSelect
                fullWidth
                name="flash_card_array"
                options={selectedFlashCards?.map((item: any) => ({
                    value: item?.id,
                    label: item?.term,
                }))}
                value={selectedFlashCards?.map((el) => el?.id)}
                label="Select Flashcard"
                sx={{
                    display: 'none',
                    width: '100%',
                    '& select': {
                        background: '#fff !important',
                        color: '#000',
                        fontWeight: 600,
                        fontFamily: 'Work Sans,sans-serif',
                    },
                }}
            />
        </>
    );
};

export default MultipleSelectField;
