/* eslint-disable prettier/prettier */
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { BugReport, HelpOutlineSharp } from '@mui/icons-material';
import AddCardIcon from '@mui/icons-material/AddCard';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import StyleIcon from '@mui/icons-material/Style';
import TranslateIcon from '@mui/icons-material/Translate';
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

export const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  card: <StyleIcon />,
  group: <GroupIcon />,
  proposedcard: <AddCardIcon />,
  person: <PersonIcon />,
  language: <TranslateIcon />,
  report: <BugReport />,
  support: <HelpOutlineSharp />,
  // group: icon('ic_group'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.analytics },
      { title: 'Flash card', path: PATH_DASHBOARD.flashCard, icon: ICONS.card },
      { title: 'Users', path: PATH_DASHBOARD.users, icon: ICONS.person },
      { title: 'Admin', path: PATH_DASHBOARD.admin, icon: ICONS.user },
      { title: 'Language', path: PATH_DASHBOARD.language, icon: ICONS.language },
      { title: 'Course', path: PATH_DASHBOARD.course, icon: ICONS.ecommerce },
      { title: 'Proposed card', path: PATH_DASHBOARD.proposedCard, icon: ICONS.proposedcard },
      { title: 'Group', path: PATH_DASHBOARD.group, icon: ICONS.group },
      { title: 'Report', path: PATH_DASHBOARD.report, icon: ICONS.report },
      { title: 'Help and Support', path: PATH_DASHBOARD.helpandSupport, icon: ICONS.support },

    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
